import React from 'react';

export default function StripePricing() {
  return (
    <div className="pricing-table-container">
      <stripe-pricing-table
        pricing-table-id="prctbl_1Q0FArBk4UsbAXWhFu7ec3Rn" // Ensure this is correct and published
        publishable-key="pk_live_51MMdUqBk4UsbAXWhT6E6PA3VKOC650eYufHFcPLoeusglWoF5GjSP6H5mMRRPKjaOsAI87qbs4yqyIuTuaCbkeT400ZMsrzbMH" // Your actual publishable key
      ></stripe-pricing-table>
    </div>
  );
}