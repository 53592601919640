import React from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';

function JobForm({ newJob, setNewJob, handleAddJob, errors, subscriptionLevel, currentJobs }) { // Added subscriptionLevel and currentJobs props
  // ... existing code ...

  const handleAddJobClick = () => {
    // Define limits based on subscription level
    const limits = {
      'Unregistered': 1,
      'Free': 3,
      'Pro': 10,
    };
    const maxJobs = limits[subscriptionLevel] || 1;

    if (currentJobs >= maxJobs) {
      // Show error notification
      alert(`You have reached the maximum number of active jobs (${maxJobs}) for your subscription level.`);
      return;
    }
    handleAddJob();
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleAddJobClick(); }}>
      <TextField
        label="Website URL"
        placeholder="https://www.example.com"
        value={newJob.url}
        onChange={(e) => setNewJob({ ...newJob, url: e.target.value })}
        error={Boolean(errors.url)}
        helperText={errors.url}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Content to Monitor"
        placeholder="e.g., new product releases"
        value={newJob.info}
        onChange={(e) => setNewJob({ ...newJob, info: e.target.value })}
        error={Boolean(errors.info)}
        helperText={errors.info}
        fullWidth
        margin="normal"
        multiline
        minRows={4}
      />

      <TextField
        label="Your Email Address"
        type="email"
        value={newJob.email}
        onChange={(e) => setNewJob({ ...newJob, email: e.target.value })}
        error={Boolean(errors.email)}
        helperText={errors.email}
        fullWidth
        margin="normal"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Frequency</InputLabel>
        <Select
          value={newJob.frequency}
          onChange={(e) => setNewJob({ ...newJob, frequency: e.target.value })}
          label="Frequency"
        >
          <MenuItem value="1">Every minute</MenuItem>
          <MenuItem value="60">Every hour</MenuItem>
          <MenuItem value="360">Every 6 hours</MenuItem>
          <MenuItem value="1440">Every day</MenuItem>
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" type="submit">
        Add Job
      </Button>
      <Typography variant="caption" color="textSecondary" className="fine-print" style={{ marginLeft: '10px' }}>
        * I am authorized to monitor the site
      </Typography>
    </form>
  );
}

export default JobForm;