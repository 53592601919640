import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Typography, Paper, Box, Snackbar, Alert, Card, CardContent, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import JobForm from './JobForm';
import JobList from './JobList';
import StripePricing from './StripePricing';
import {
  DollarSign,
  Briefcase,
  Package,
  Newspaper,
  Calendar,
  Rocket,
  FileText,
  Bell,
} from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || '/api';
const WS_URL = process.env.REACT_APP_WS_URL || `wss://${window.location.host}/ws`;

const useCases = [
  { icon: DollarSign, title: 'Price Drops', description: 'Monitor product price changes' },
  { icon: Briefcase, title: 'Job Listings', description: 'Stay updated on new job posts' },
  { icon: Package, title: 'Product Availability', description: 'Get alerts when items are restocked' },
  { icon: Newspaper, title: 'New Articles', description: 'Be notified of new content' },
  { icon: Calendar, title: 'Event Changes', description: 'Track updates to event details' },
  { icon: Rocket, title: 'Competitor Updates', description: 'Monitor new product launches' },
  { icon: FileText, title: 'Policy Changes', description: 'Watch for updates to terms or policies' },
  { icon: Bell, title: 'Custom Alerts', description: 'Set up alerts for any specific changes' },
];

function UseCases() {
  return (
    <Card variant="outlined" style={{ marginBottom: '2rem' }}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Unlimited Use Cases
        </Typography>
        <Grid container spacing={2}>
          {useCases.map((useCase, index) => {
            const IconComponent = useCase.icon;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <IconComponent style={{ marginRight: '8px', marginTop: '4px' }} />
                  <div>
                    <Typography variant="subtitle1" component="h3">
                      {useCase.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {useCase.description}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 2 }}>
          * Limited to monitoring initial pages and cannot handle websites requiring login, deep scrolling, or navigation.
        </Typography>
      </CardContent>
    </Card>
  );
}

function App() {
  const [jobs, setJobs] = useState([]);
  const [newJob, setNewJob] = useState({
    url: '',
    info: '',
    frequency: '1440',
    email: '',
    status: 'Active',
  });
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [subscriptionLevel, setSubscriptionLevel] = useState('Unregistered');
  const [activeJobsCount, setActiveJobsCount] = useState(0);

  // Define limits for subscription levels
  const limits = {
    'Unregistered': { maxJobs: 1 },
    'Free': { maxJobs: 3 },
    'Pro': { maxJobs: 10 },
  };

  const ws = useRef(null);
  const reconnectTimeoutRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    const wsUrl = WS_URL;
    console.log('Attempting to connect WebSocket to:', wsUrl);

    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log('WebSocket connection established.');
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received WebSocket message:', message);

      if (message.type === 'jobUpdate') {
        const updatedJob = message.job;
        setJobs((prevJobs) => prevJobs.map((job) => (job.id === updatedJob.id ? updatedJob : job)));
        setNotification({
          open: true,
          message: `Job "${new URL(updatedJob.url).hostname.replace(/^www\./, '')}" has been updated.`,
          severity: 'info',
        });
      }
    };

    ws.current.onclose = (event) => {
      console.log('WebSocket connection closed. Code:', event.code, 'Reason:', event.reason);
      reconnectTimeoutRef.current = setTimeout(() => {
        console.log('Attempting to reconnect...');
        connectWebSocket();
      }, 5000);
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }, []);

  const fetchJobs = useCallback(async (currentUserId) => {
    try {
      const url = `${API_URL}/data?userId=${currentUserId}`;
      console.log('Fetching jobs from:', url);
      const response = await fetch(url);
      
      if (response.status === 404) {
        console.log('No jobs found for user.');
        setJobs([]);
        setSubscriptionLevel('Unregistered');
        setActiveJobsCount(0);
        return;
      }

      if (!response.ok) {
        throw new Error(`Failed to fetch jobs: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Fetched jobs:', data);
      const activeJobs = data.filter(job => job.status === 'Active');
      setJobs(data.filter(job => job.status !== 'Deleted'));

      // Determine subscription level from jobs data
      if (data.length > 0) {
        setSubscriptionLevel(data[0].subscriptionLevel || 'Unregistered');
      } else {
        setSubscriptionLevel('Unregistered');
      }

      // Calculate active jobs count
      setActiveJobsCount(activeJobs.length);
    } catch (error) {
      console.error('Error when fetching jobs:', error);
      setNotification({
        open: true,
        message: 'Failed to fetch jobs.',
        severity: 'error',
      });
      setJobs([]);
      setSubscriptionLevel('Unregistered');
      setActiveJobsCount(0);
    }
  }, [API_URL]);

  useEffect(() => {
    let storedUserId = localStorage.getItem('userId');
    if (!storedUserId) {
      storedUserId = uuidv4();
      localStorage.setItem('userId', storedUserId);
    }
    console.log('Current userId:', storedUserId);
    setUserId(storedUserId);

    // Fetch jobs for the user once
    if (storedUserId) {
      fetchJobs(storedUserId);
    }

    // Initialize WebSocket connection
    connectWebSocket();

    // Cleanup on unmount
    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [fetchJobs, connectWebSocket]);

  const deleteJob = async (id) => {
    try {
      const jobToDelete = jobs.find(job => job.id === id);
      if (jobToDelete) {
        const updatedJob = { ...jobToDelete, status: 'Deleted' };
        const response = await fetch(`${API_URL}/data/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJob),
        });
        if (!response.ok) {
          throw new Error(`Failed to delete job: ${response.status} ${response.statusText}`);
        }
        setJobs((prevJobs) => prevJobs.filter(job => job.id !== id));
        setNotification({
          open: true,
          message: 'Job deleted successfully!',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting job:', error);
      setNotification({
        open: true,
        message: 'Failed to delete job.',
        severity: 'error',
      });
    }
  };

  const saveJob = async (job) => {
    try {
      const response = await fetch(`${API_URL}/data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(job),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to save job: ${response.status}`);
      }
      const result = await response.json();
      setJobs((prevJobs) => [...prevJobs, result.job]);
      setActiveJobsCount((prevCount) => prevCount + 1);
      setNotification({
        open: true,
        message: 'Job added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Network error when saving job:', error);
      setNotification({
        open: true,
        message: `Failed to add job: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const toggleJobStatus = async (id) => {
    const jobToUpdate = jobs.find((job) => job.id === id);
    if (jobToUpdate) {
      const updatedJob = {
        ...jobToUpdate,
        status: jobToUpdate.status === 'Active' ? 'Stopped' : 'Active',
      };
      try {
        const response = await fetch(`${API_URL}/data/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJob),
        });
        if (!response.ok) {
          throw new Error(`Failed to update job: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();
        setJobs((prevJobs) => prevJobs.map((job) => (job.id === id ? result.job : job)));
        setNotification({
          open: true,
          message: `Job ${updatedJob.status === 'Active' ? 'started' : 'stopped'} successfully!`,
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating job status:', error);
        setNotification({
          open: true,
          message: 'Failed to update job status.',
          severity: 'error',
        });
      }
    }
  };

  const handleAddJob = () => {
    const validationErrors = {};
    let processedUrl = newJob.url.trim();
    if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
      processedUrl = 'https://' + processedUrl;
    }

    if (!isValidUrl(processedUrl)) {
      validationErrors.url = 'Please enter a valid URL (e.g., https://www.example.com)';
    }
    if (!isValidEmail(newJob.email)) {
      validationErrors.email = 'Please enter a valid email address';
    }
    if (newJob.info.trim() === '') {
      validationErrors.info = 'Description cannot be empty';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const jobToSave = {
      id: Date.now().toString(),
      userId: userId,
      url: processedUrl,
      info: newJob.info,
      frequency: newJob.frequency,
      email: newJob.email,
      status: 'Active',
      startTime: new Date().toISOString(),
    };

    saveJob(jobToSave);
    setNewJob({ url: '', info: '', frequency: '1440', email: '', status: 'Active' });
    setErrors({});
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const isValidEmail = (string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(string);
  };

  const handleSubscriptionSuccess = () => {
    // Fetch jobs again to update the UI based on new subscription level
    fetchJobs(userId);
    setNotification({
      open: true,
      message: 'Subscription successful! Thank you for subscribing.',
      severity: 'success',
    });
  };

  const canAddMoreJobs = useCallback(() => {
    if (subscriptionLevel === 'Unregistered' || subscriptionLevel === 'Free') {
      return activeJobsCount < limits[subscriptionLevel].maxJobs;
    }
    return true; // For 'Pro' users or any other subscription levels
  }, [subscriptionLevel, activeJobsCount, limits]);

  return (
    <Container maxWidth="md">
      <Box mb={2}>
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          Website Content Monitoring Tool
        </Typography>
      </Box>

      <Box mb={4}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Typography variant="body1" align="center">
            Enter the URL and describe in plain words what you want to monitor. Our AI will alert you based on your criteria.
          </Typography>
        </Paper>
      </Box>

      <Box mb={4}>
        <UseCases />
      </Box>

      {canAddMoreJobs() ? (
        <Box mb={4}>
          <JobForm
            newJob={newJob}
            setNewJob={setNewJob}
            handleAddJob={handleAddJob}
            errors={errors}
            subscriptionLevel={subscriptionLevel}
            currentJobs={activeJobsCount}
          />
        </Box>
      ) : null}

      <Box mb={2}>
        <Typography variant="h5" component="h2" gutterBottom>
          Your Monitoring Jobs
        </Typography>
      </Box>
      {jobs.length === 0 ? (
        <Typography>You don't have any monitoring jobs yet.</Typography>
      ) : (
        <JobList
          jobs={jobs}
          toggleJobStatus={toggleJobStatus}
          deleteJob={deleteJob}
          subscriptionLevel={subscriptionLevel}
        />
      )}

      {!canAddMoreJobs() && (
        <Box mt={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Subscribe to Unlock More Jobs
          </Typography>
          <StripePricing onSubscriptionSuccess={handleSubscriptionSuccess} />
        </Box>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
