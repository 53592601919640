import React from 'react';
import { List, ListItem, ListItemText, Button, Stack, Card, CardContent, Typography, Collapse, Paper, Box } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import { useState, useEffect, useCallback } from 'react';

const frequencyMap = {
  "1": "Every minute",
  "60": "Every hour",
  "360": "Every 6 hours",
  "1440": "Every day"
};

function JobList({ jobs, toggleJobStatus, deleteJob, subscriptionLevel }) {
  const [openJobIds, setOpenJobIds] = useState([]);

  // Define activeJobs within the component scope
  const activeJobs = jobs.filter(job => job.status === 'Active').length;

  const handleToggle = (id) => {
    setOpenJobIds((prevOpen) =>
      prevOpen.includes(id) ? prevOpen.filter((jobId) => jobId !== id) : [...prevOpen, id]
    );
  };

  const fetchJobs = useCallback(() => {
    // Implement the logic to fetch jobs from the server
    // This function should be called when the component mounts and whenever a WebSocket message is received
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  return (
    <Stack spacing={3}> {/* Added Stack with spacing for consistent gaps */}
      {jobs.map((job) => (
        <Card key={job.id} style={{ width: '100%' }}>
          <CardContent>
            {/* URL, Frequency, Count, and Criteria on the same row with consistent styling */}
            <Box display="flex" alignItems="center" flexWrap="wrap" className="job-details">
              <Box sx={{ flex: '0 0 200px', display: 'flex', alignItems: 'center' }}>
                <LinkIcon className="link-icon" fontSize="small" />
                <Typography variant="body1" className="job-url-text" sx={{ ml: 1 }}>
                  <a
                    href={job.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="job-url-link"
                  >
                    {new URL(job.url).hostname.replace(/^www\./, '')}
                  </a>
                </Typography>
              </Box>
              <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                  <strong>Frequency:</strong> {frequencyMap[job.frequency] || `${job.frequency} minutes`}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                  <strong>Count:</strong> {job.analysisHistory ? job.analysisHistory.length : 0}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mr: 2, maxWidth: '300px', wordBreak: 'break-word' }}>
                  <strong>Criteria:</strong> {job.info}
                </Typography>
              </Box>
            </Box>
            
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center">
                {/* Additional elements can be added here if needed */}
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color={job.status === 'Active' ? 'error' : 'primary'}
                  onClick={() => toggleJobStatus(job.id)}
                  disabled={
                    job.status !== 'Active' &&
                    (
                      (subscriptionLevel === 'Unregistered' && activeJobs >= 1) ||
                      (subscriptionLevel === 'Free' && activeJobs >= 3) ||
                      (subscriptionLevel === 'Pro' && activeJobs >= 10)
                    )
                  } // Replaced the comment with a valid boolean expression
                  className="job-button"
                >
                  {job.status === 'Active' ? 'Stop' : 'Start'}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => deleteJob(job.id)}
                  className="job-button"
                >
                  Delete
                </Button>
                {/* Always show the "Show Analysis" button */}
                <Button
                  variant="text"
                  onClick={() => handleToggle(job.id)}
                  endIcon={openJobIds.includes(job.id) ? <ExpandLess /> : <ExpandMore />}
                  className="job-button"
                >
                  {openJobIds.includes(job.id) ? 'Hide Analysis' : 'Show Analysis'}
                </Button>
              </Stack>
            </Stack>

            {/* Always render Collapse if analysisHistory is defined */}
            {job.analysisHistory !== undefined && (
              <Collapse in={openJobIds.includes(job.id)} timeout="auto" unmountOnExit>
                <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
                  History:
                </Typography>
                {job.analysisHistory && job.analysisHistory.length > 0 ? (
                  <List>
                    {job.analysisHistory.map((history, index) => (
                      <ListItem key={index}>
                        <Paper
                          elevation={2}
                          style={{
                            padding: '10px',
                            width: '100%',
                            backgroundColor: history.alert ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 128, 0, 0.1)',
                          }}
                        >
                          <ListItemText
                            primary={history.result}
                            secondary={new Date(history.timestamp).toLocaleString()}
                          />
                        </Paper>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Pending
                  </Typography>
                )}
              </Collapse>
            )}
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}

export default JobList;